import React, { useEffect, useState } from 'react';
import Head from 'next/head';

import Checkers from '../../helpers/class/Checkers';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../helpers/MethodHelper";

import Script from 'next/script';


function BuilderHead ( props ) {

    const isRateParityWebAppPage = props.hasOwnProperty('isRateParityWebAppPage') ? props.isRateParityWebAppPage : false

    let manifestPath = '/manifest.json';
    if (Checkers.booleanState(props.property)) {
        manifestPath = '../../' + manifestPath.split('/')[1]
    }

    const showRateParityWidget = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_general_show_rateparity_widget') == 1 ? true : false
    );


    const showCookieYes = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_head_cookies_cookieyes_enable') == 1 ? true : false
    );


    const cookiesYesIdentifier = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_head_cookies_cookieyes_identifier')
    );

    const cookiesYesScript = () => {
        if (Checkers.isValidString(cookiesYesIdentifier)) {
            return 'https://cdn-cookieyes.com/client_data/'+cookiesYesIdentifier+'/script.js'
        }

        return null
    }

    const rateParityHasLoaded = () => {
        if (typeof window !== "undefined") {
            return document.getElementById('rp-allinone')
        }

        return true
    }

    const cookieYesHasLoaded = () => {
        if (typeof window !== "undefined") {
            return document.getElementsByClassName('cky-preference-center').length>0
        }

        return true
    }


    useEffect(() => {
        if (props.font != null) {
            const head = document.getElementsByTagName('head')[0];
            const style = document.createElement('style');
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';

            switch (props.font) {
                case 'Roboto Slab':
                    link.href = 'https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap';
                    style.innerText = `h1,h2,h3,h4,h5,h6,.font-montserrat{font-family: 'Roboto Slab', serif !important}`;
                    break;
                case 'Serif':
                    link.href = 'https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700&amp;subset=greek&display=swap';
                    style.innerText = `h1,h2,h3,h4,h5,h6,.font-montserrat{font-family: 'Roboto Slab', serif !important}`;
                    break;
                case 'Roboto':
                    link.href = 'https://fonts.googleapis.com/css?family=Roboto:300,400,700&amp;subset=greek&display=swap';
                    style.innerText = `h1,h2,h3,h4,h5,h6,.font-montserrat{font-family: 'Roboto Slab', sans-serif !important}`;
                    break;
                case 'Vollkorn':
                    link.href = 'https://fonts.googleapis.com/css?family=Vollkorn:400,600,700&amp;subset=greek&display=swap';
                    style.innerText = `h1,h2,h3,h4,h5,h6,.font-montserrat{font-family: 'Vollkorn', serif !important}`;
                    break;
                case 'Noto Sans':
                    link.href = 'https://fonts.googleapis.com/css?family=Noto+Sans:400,700&amp;subset=greek&display=swap';
                    style.innerText = `h1,h2,h3,h4,h5,h6,.font-montserrat{font-family: 'Noto Sans', sans-serif !important}`;
                    break;
                case 'Cousine':
                    link.href = 'https://fonts.googleapis.com/css?family=Cousine:400,700&amp;subset=greek&display=swap';
                    style.innerText = `h1,h2,h3,h4,h5,h6,.font-montserrat{font-family: 'Roboto Slab', monospace !important}`;
                    break;
                case 'Playfair':
                    link.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap';
                    style.innerText = `h1,h2,h3,h4,h5,h6,.font-montserrat{font-family: 'Playfair Display', serif !important}`;
                    break;
                default:
                    link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons&display=swap';
                    break;
            }
            head.appendChild(link);
            head.appendChild(style);
        }
    });

    let googleSchema = {
        "@context": "https://schema.org/",
        "@type": "Organization",
        "name": `${props.googleSchemaTitle}`,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": `${props.googleSchemaStreetAddress}`,
            "addressLocality": `${props.googleSchemaCity}`,
            "addressRegion": `${props.googleSchemaCountry}`,
            "postalCode": `${props.googleSchemaZip}`,
        },
        "telephone": `${props.googleSchemaPhone}`
    }

    return (
        <Head>
            <title>{props.title}</title>
            {props.keywords != null && <meta name="keywords" content={props.keywords}/>}
            <meta name="author" content={props.titleOfficial}/>
            <meta name="robots" content="index, follow"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            <meta name="theme-color" content={props.designColor}/>
            <meta name="referrer" content={'strict-origin'}/>
            {props.icon_60 != null && props.icon_60 != '' &&
                <>
                    <link rel={'icon'} type={'image/png'} sizes={'16x16'} href={props.icon_60}/>
                    <link rel={'icon'} type={'image/png'} sizes={'32x32'} href={props.icon_60}/>
                    <link rel={'apple-touch-icon'} href={props.icon_60}/>
                    <link rel={'apple-touch-icon'} href={props.icon_60}/>
                </>

            }
            {props.icon_76 != null && props.icon_76 != '' &&
                <link rel={'apple-touch-icon'} sizes={'76x76'} href={props.icon_76}/>}
            {props.icon_120 != null && props.icon_120 != '' &&
                <link rel={'apple-touch-icon'} sizes={'120x120'} href={props.icon_120}/>}
            {props.icon_152 != null && props.icon_152 != '' &&
                <link rel={'apple-touch-icon'} sizes={'152x152'} href={props.icon_152}/>}
            {props.icon_256 != null && props.icon_256 != '' &&
                <>
                    <link rel={'icon'} type={'image/x-icon'} href={props.icon_256}/>
                    <link rel={'shortcut icon'} type={'image/x-icon'} href={props.icon_256}/>
                </>
            }
            {props.icon != null && props.icon != '' && <link rel={'apple-touch-icon'} href={props.icon}/>}
            <meta name="apple-mobile-web-app-capable" content="yes"/>
            <meta name="apple-touch-fullscreen" content="yes"/>
            <meta name="apple-mobile-web-app-status-bar-style" content="default"/>

            {props.manager == false && <meta property="type" content="place"/>}
            {props.manager == false && <meta property="og:type" content="place"/>}
            {props.manager == false && <meta property="twitter:type" content="place"/>}

            <meta name="title" content={props.site_name_fb_meta + " | " + props.title_fb_meta}/>
            <meta name="og:title" content={props.site_name_fb_meta + " | " + props.title_fb_meta}/>
            <meta name="twitter:title" content={props.title_fb_meta}/>

            <meta name="description" content={Checkers.isValidString(props.desc_fb_meta) ? props.desc_fb_meta : props.title }/>
            <meta name="og:description" content={props.desc_fb_meta}/>
            <meta name="twitter:description" content={props.desc_fb_meta}/>

            <meta name="image" content={props.image_fb_meta}/>
            <meta name="og:image" content={props.image_fb_meta}/>
            <meta name="twitter:image" content={props.image_fb_meta}/>

            <meta property="og:image:width" content="250"/>
            <meta property="og:image:height" content="250"/>

            {(props.lat_fb_meta != null && props.lat_fb_meta != '') ?
                <meta property="place:location:latitude" content={props.lat_fb_meta}/> : null}
            {(props.lon_fb_meta != null && props.lon_fb_meta != '') ?
                <meta property="place:location:longitude" content={props.lon_fb_meta}/> : null}

            <meta property="url" content={props.domain}/>
            <meta property="og:url" content={props.domain}/>
            <meta property="twitter:url" content={props.domain}/>

            <meta name="twitter:card" content="summary"/>
            <meta property="og:site_name" content={props.site_name_fb_meta}/>
            <meta name="twitter:image:alt" content={props.twitter_img_alt}/>
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(googleSchema)}}/>
            <link rel="manifest" href={manifestPath}/>

            {
                (!cookieYesHasLoaded() && showCookieYes && cookiesYesScript != null) ?
                    <script id="cookieyes" type="text/javascript"
                            src={cookiesYesScript()} defer></script>
                : <></>
            }

            {
                (!rateParityHasLoaded() && !isRateParityWebAppPage && showRateParityWidget) ?
                    <>
                        <script type="text/javascript" src="https://code.rateparity.com/versions/installer.min.js"
                                defer></script>
                    </>
                    : <></>
            }
        </Head>
    )
}

export default BuilderHead;
